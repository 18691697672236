.links-form {
    &-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        height: 170vh;
        width: 100%;
        justify-content: center;
        background: rgba(0, 0, 0, 0.41);
    }
    &-container {
        max-width: 538px;
        width: 538px;
        display: flex;
        flex-direction: column;
        background: white;
        height: 400px;
        padding: 50px;
        border-radius: 10px;
        position: relative;

        .heading {
            font-size: 24px;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 33px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .delete-link {
                cursor: pointer;
                &--icon {
                    margin-right: 5px;
                }
                display: flex;
                align-items: center;
                color: #EA4335;
                font-size: 16px;
                font-weight: 400;
                transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
                transition-duration: 300ms;
                &:hover {
                    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
                    transition-duration: 300ms;
                    transform: scale(1.1);

                }
            }
        }
    }
    &-body {
        &--field {
            margin-bottom: 19px;
        }
        &--preview {
            .heading {
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                margin-bottom: 3px
            }
            .preview-link {
                word-break: break-all;

            }
        }
    }
    &-footer {
        margin-top: 50px;
        display: flex;
        justify-content: center;
    }
}

.otp-verification {
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 70vh;
}

.resend-otp {
    cursor: pointer;
    color: blue !important;
    margin: 10px auto;
    width: fit-content;
}
