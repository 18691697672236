.ant-form-item-control-input {
    min-height: 50px !important;
}

.input-style {
    height: 50px;
    font-size: 14px;
}

.login-button {
    width: 100%;
    height: 50px;
    font-size: 20px;
    cursor: pointer;
    border-radius: 999px !important;
}

.label {
    color: black;
}

.grey {
    color: black;
}

.form-wrapper {
    display: flex;
        justify-content: center;
    padding: '20px';
    color: '#fff';

    .left {
        .input-wrapper, .dropdown-wrapper {
            margin-bottom: 15px;
            font-size: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%
        }
    }
}

.ant-select-selection-search-input {
    @media screen and (max-width: 768px)  {
        height: 20px;
        font-size: 12px;
    }
    height: 30px;
    margin-top:25px;
    font-size: 14px;
    width: 98%;
    padding: 4px;
    background-color: transparent;
    border-bottom: 1px solid black;
    outline: none !important;
}

.form-control {
    height: 55px !important;
    width: 100% !important;
    @media screen and  (max-width: 768px) {
        height: 45px !important;
        width: 100% !important;
    }
}

.verification-block {
    width: 100%;
    border: 1px solid black;
    height: 65px;
    padding: 20px;
    margin-bottom: 15px;
    margin-top: 30px;
    border-radius: 8px;
    background-color: #32206a;
    color: white;
}

.user-fields {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 6px;
    padding: 30px;
    @media screen and  (min-width: 1180px) {
        grid-template-columns: repeat(4, 1fr);
    }
}