.header-links {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 700;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    color: #8A9DF3; // Change to your desired hover color
  }
}

.main-heading {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 7rem;
}

.ant-collapse .ant-collapse-content{
  border-top: none;
  border-bottom: 1px solid #d9d9d9;
}

.ant-collapse .ant-collapse-item:last-child > .ant-collapse-content{
  border-radius: 0px;
}



.ant-collapse .ant-collapse-content > .ant-collapse-content-box{
  padding-top: 0px;
}

.input-field-e {
  padding: 10px;
  border: 1px solid #ccc;
  width: 100%;
  max-width: 650px;

  :focus {
    border-color: black;
  }

  :hover {
    border-color: black;
  }

}