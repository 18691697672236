.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid grey;
    padding: 10px;
    height: 70px;
    @media screen and (max-width: 768px) {
    }
}

.link-container {
    display: flex;
    gap: 10px;
    @media screen and (max-width: 768px) {
    }
}

.logo-footer {
    cursor: pointer;
    @media screen and (min-width: 1200px) {
        margin-left: 15px;
    }
                @media screen and (max-width: 768px) {
    }
}

.arr {
    @media screen and (max-width: 768px) {
        align-items: 'center';
    }
}