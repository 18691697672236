@import '../../../public//styles/utils/variables';
@import '../../../public//styles/utils/mixins';

.text-box-label {
    margin-bottom: 5px !important;
    color: black;
}

.ant-input {
    @media screen and (max-width: 768px)  {
        height: 45px;
        font-size: 12px;
    }
    height: 55px;
    margin-top:25px;
    font-size: 14px;
    width: 98%;
    padding: 4px;
    background-color: white;
    border: 1px solid black;
}

.ant-input::placeholder {
    color: #999999;
}

.ant-input:hover {
    @media screen and (max-width: 768px)  {
        height: 45px;
        font-size: 12px;
    }
    height: 55px;
    margin-top:25px;
    font-size: 14px;
    width: 98%;
    padding: 4px;
    background-color: white;
    border: 1px solid black;
}

.ant-input:not(:hover) {
    @media screen and (max-width: 768px)  {
        height: 45px;
        font-size: 12px;
    }
    height: 55px;
    margin-top:25px;
    font-size: 14px;
    width: 98%;
    padding: 4px;
    background-color: white;
    border: 1px solid black;
}

.ant-input-status-error:hover {
    border-color: #ff4d4f !important;
}

