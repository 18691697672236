#components-layout-demo-top-side-2 .logo {
    float: left;
    width: 150px;
    height: 31px;
    margin: 16px 24px 16px 0;
  }
  
  .site-layout-background {
    background-color: #7e5ae2;
  }

.ant-menu ant-menu-root ant-menu-inline ant-menu-light css-dev-only-do-not-override-1xg9z9n {
    background-color: #7e5ae2 !important;
}

// .ant-layout-sider-children ul {
//   background: #c5b5f2 !important;
// }