@import '../../../public//styles/utils/variables';
@import '../../../public//styles/utils/mixins';

.text-box-label {
    margin-bottom: 5px !important;
    color: #7e7575 !important;
}

.ant-input {
    @media screen and (max-width: 768px)  {
        height: 45px;
        font-size: 12px;
    }
    height: 55px;
    margin-top:25px;
    font-size: 14px;
    width: 98%;
    padding: 4px;
    background-color: white;
    border-radius: 8px;
}

.ant-select-single {
    @media screen and (max-width: 768px) {
        height: 45px;
        font-size: 12px;
    }

    height: 55px;
    margin-top:25px;
    font-size: 14px;
    width: 98%;
    background-color: white;
    border-radius: 8px;
}
.ant-input::placeholder {
    color: #7e7575 !important;
}

.ant-input:hover {
    @media screen and (max-width: 768px)  {
        height: 45px;
        font-size: 12px;
    }
    height: 55px;
    margin-top:25px;
    font-size: 14px;
    width: 98%;
    padding: 4px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid grey;
}

.ant-input:not(:hover) {
    @media screen and (max-width: 768px)  {
        height: 45px;
        font-size: 12px;
    }
    height: 55px;
    margin-top:25px;
    font-size: 14px;
    width: 98%;
    padding: 4px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid grey;
}

.ant-input-status-error:hover {
    border-color: #ff4d4f !important;
}

