.image-container {
    position: relative;
    width: fit-content;
  }
  
  .background-image {
    width: 600px;
    height: auto;
  }
  
  .floating-image {
    width: 350px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    animation: float 5s ease-in-out infinite;
  }
  
  @keyframes float {
    0%, 100% {
      transform: translate(-50%, -20px);
    }
    50% {
      transform: translate(-50%, 20px);
    }
  }

  .custom-block {
    position: relative;
    width: 400px;
    height: 211px;
    padding: 20px;
    background-color: #fff;
    text-align: center;
    margin: 20px;
    border-radius: 5px;
    padding-top: 20px;
    font-family: Aktivgrotesk, sans-serif;
  }
  
  .number {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .label {
    font-size: 28px;
    color: black;
    font-weight: bold;
  }