.button-wrapper {
    display: flex;
    justify-content: center;
    button {
        outline: none;
        border: none;
        border-radius: 5px;
        box-shadow: none;
        &:focus {
            box-shadow: none !important;
        }
        &:active {
            border: none !important;
            outline: none !important;
        }
    }
    .btn-plain {
        cursor: pointer;
        transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transition-duration: 300ms;
        background: white;
        min-width: 115px;
        color: #999999;
        &:hover {
            transition: background-color 0.5s;
            color: black;
            transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
            transition-duration: 300ms;
            transform: scale(1.1);
        }
    }
    .btn-primary {
        cursor: pointer;
        background: #7e5ae2;
        min-width: 115px;
        color: white;
        &:hover {
            transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
            transition-duration: 300ms;
            transform: scale(1.1);
        }
    }
    .btn-outline-primary {
        cursor: pointer;
        background: #7e5ae2;
        border: 1px solid #7e5ae2;
        border-radius: 10px;
        color: white;
        min-width: 115px;
        &:hover {
            background: #7e5ae2;
            color: white;
            transition: background-color 0.5s;

        }
        &:active {
            background-color: #7e5ae2 !important;
        }
    }
    .size-md {
        height: 55px;
    }
    .size-sm {
        height: 42px;
        min-width: 81px;
    }
    .btn-disabled {
        opacity: 0.4;
        cursor: not-allowed;
        pointer-events: none;
    }
    .btn-loading {
        background-color: #7e5ae2;
    }


}
