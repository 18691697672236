.text-block {
    margin-top: 37px;
    margin-left: 52px;
    margin-right: 49px;
    margin-bottom: 60px;
}

.big-heading {
    color: black;
    text-align: center;
    letter-spacing: -.02em;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Poppins-Klaviyo-Hosted, Arial, 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 60px;
    font-weight: 500;
    line-height: 1.2;
}