.heading-h1 {
    color: var(--gray--black);
    text-align: center;
    letter-spacing: -.02em;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Aktivgrotesk, sans-serif;
    font-size: 60px;
    font-weight: 500;
    line-height: 1.2;
    color:black
}

.desc {
    text-align: center;
    letter-spacing: -.02em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 32px;
    color: black;
}

.logo-style {
    width: 150px;
    margin-bottom: 36px;
    margin-left: auto;
    margin-right: auto;
}

.button-purple {
    min-height: 48px;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    background-color: var(--purple--base);
    color: #fff;
    text-align: center;
    letter-spacing: -.02em;
    flex-direction: row;
    flex: none;
    justify-content: center;
    align-items: center;
    padding: 12px 32px;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    background-color: #7e5ae2;
}