.ant-form-item-control-input {
    min-height: 50px !important;
}

.input-style {
    @media screen and (max-width: 768px)  {
        height: 20px;
        font-size: 12px;
    }
    height: 55px;
    margin-top:25px;
    font-size: 14px;
    width: 98%;
    padding: 4px;
    border-radius: 4px 0 0 4px;
    background-color: white;
    border: 1px solid grey;
    border-right: none;
}

.grey {
    color: grey;
}

.form-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 32px;

    .left {
        .input-wrapper, .dropdown-wrapper {
            margin-bottom: 15px;
            font-size: '24px';
            display: 'flex';
            justify-content: 'center';
            align-items: 'center';
            height: '100%'
        }
    }
}

.login-button  {
    background-color: white;
    color: rgb(126, 90, 226);
    width: 78%;
    height: 50px;
    font-size: 20px;
    font-weight: 700;
    margin-top: 10px;
    border-radius: 999px !important;
}

.center {
    display: 'flex';
    flex-direction: 'column';
    justify-content: 'center';
    align-items: 'center'
}

.transparent-underline-input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid grey;
    outline: none;
}

.highlighted-text {
    color: white;
    text-decoration: underline;
    font-size: 20px;
    font-weight: 600;
    margin-left: 3px;
    margin-right: 5px;
    cursor: pointer;
}

.left-heading {
    font-size: '34px';
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
    height: '100%';
    flex-direction: 'column';
    max-width: 452px;
}

.link {
    color: white;
    cursor: pointer;
    text-decoration: underline;
    margin-left: 4px;
}

.heading {
    text-align: center;
    color: white;
    font-weight: 400;
    font: 36px
}

.heading-3 {
    color: rgb(126, 90, 226);
    text-align: center;
    margin-bottom: 36px;
    font-size: 60px;
    font-weight: 500;
    @media screen and (max-width: 786px) {
        font-size: 48px;
     }
}

.paragraph-large {
    text-align: center;
    letter-spacing: -.02em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 24px;
    @media screen and (max-width: 786px) {
       font-size: 18px;
    }
    line-height: 32px;
}

.check-icon {
    width: 20px;
    text-align: left;
    margin-right: 10px;
}

.check-list {
    padding: 30px;
    font-size: larger;
    list-style: none;
}


.ant-switch .ant-switch-handle::before{
    background-color: #7e5ae2;
}

.ant-switch{
    background-color: white;
}

.ant-switch.ant-switch-checked{
    background-color: #d0c1fc;
}