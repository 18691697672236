@import '../../../public//styles/utils/variables';
@import '../../../public//styles/utils/mixins';

.text-box-label {
    margin-bottom: 5px !important;
    color: grey;
}

.ant-input {
    @media screen and (max-width: 768px)  {
        height: 45px;
        font-size: 12px;
    }
    height: 55px;
    margin-top:25px;
    font-size: 14px;
    width: 98%;
    padding: 4px;
    background-color: white;
    border-radius: 4px 0 0 4px;
}

.ant-input::placeholder {
    color: #999999;
}

.ant-input:hover {
    @media screen and (max-width: 768px)  {
        height:45px;
        font-size: 12px;
    }
    height: 50px;
    margin-top:25px;
    font-size: 14px;
    width: 98%;
    padding: 4px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid transparent;
}

.ant-input:not(:hover) {
    @media screen and (max-width: 768px)  {
        height: 45px;
        font-size: 12px;
    }
    height: 55px;
    margin-top:25px;
    font-size: 14px;
    width: 98%;
    padding: 4px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid grey;
}

.ant-input-status-error:hover {
    border-color: #ff4d4f !important;
}


.row-flex {
    display: flex;
    flex-direction: row;
}

.anticon[tabindex] {
    margin-top: 25px;
    padding: 4px;
    border-radius: 0 4px 4px 0;
    height: 50px;
    border: 1px solid grey;
    border-left: none;
    background-color: white;
    color: #999999;
    font-size: x-large;
}

.input-style {
    padding: 20px !important;
    font-weight: lighter !important; /* Change placeholder text style */
}

.input-style::placeholder {
    color: #807c7c; /* Change placeholder text color */
    font-weight: 300;
  }